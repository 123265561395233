import React, { useEffect, useState } from "react";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { useNavigate, useParams } from "react-router-dom";
import { BASE_URL, tmpToken } from "../../utils/utils";
import axios from "axios";
import { useAllContext } from "../Context/GlobalContext";
import { useAllClientContext } from "../Context/ClientContext";
import { useAllInvoiceContext } from "../Context/InvoiceContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
const MultiVersionOverview = () => {
  const navigate = useNavigate();
  const { allCompanies, setOpen, companyData } = useAllContext();
  let { allClients } = useAllClientContext();
  const { id } = useParams();
  const { getAllInvoices } = useAllInvoiceContext();
  const [modalOpen, setModalOpen] = useState({
    delete: false,
    newVersion: false,
  });
  const [invoice, setInvoice] = useState({});
  const [internalLoader, setInternalLoader] = useState(false);

  //GET ONE INVOICE:
  const getInvoice = async (e) => {
    try {
      let data = await axios.get(
        `${BASE_URL}/invoiceV2/one?id=${id}&company=${companyData?.companyId}`,
        {
          headers: {
            // "x-access-token": getCookie("token"),
            "x-access-token": tmpToken,
          },
        }
      );
      setInvoice(data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  // HANDLE NEW VERSION SUBMIT:
  const handleNewVersionSubmit = async (e) => {
    try {
      delete invoice?._id;
      let company = allCompanies?.find((d) => d?._id === invoice?.company?._id);
      let client = allClients?.find((d) => d?._id === invoice?.client?._id);
      let newVersion = {
        ...invoice,
        v: invoice?.versions?.length + 2,
        company: { ...company, cid: company?._id },
        client,
      };

      await axios
        .post(`${BASE_URL}/invoiceV2`, newVersion, {
          headers: {
            // "x-access-token": getCookie("token"),
            "x-access-token": tmpToken,
          },
        })
        .then((res) => {
          axios
            .put(
              `${BASE_URL}/invoiceV2?id=${id}&month=${
                new Date(invoice?.invoice_date)?.getMonth() + 1
              }&year=${new Date(
                invoice?.invoice_date
              )?.getFullYear()}&company=${companyData?.companyId}`,
              {
                versions: [
                  ...invoice?.versions,
                  { id: res?.data?._id, createdAt: new Date() },
                ],
              },
              {
                headers: {
                  // "x-access-token": getCookie("token"),
                  "x-access-token": tmpToken,
                },
              }
            )
            .then((res) => {
              getInvoice();
              setOpen({
                msg: "New version created successfully !",
                open: true,
                severity: "success",
              });
            })
            .catch((er) => {
              console.log(er);
              setOpen({
                msg: "something went wrong please try again letter",
                open: true,
                severity: "error",
              });
            });
        })
        .catch((er) => {
          console.log(er);
          setOpen({
            msg: "something went wrong please try again letter",
            open: true,
            severity: "error",
          });
        })
        .finally(() => {
          getInvoice();
          setModalOpen((prev) => ({ ...prev, newVersion: false }));
        });

      console.log(newVersion);
    } catch (error) {
      console.error(error);
      setOpen({
        msg: "something went wrong please try again letter",
        open: true,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <div className="flex flex-col gap-[50px] py-2">
      <div className="w-full flex flex-col gap-[20px]  bg-white">
        <section className="flex  items-center justify-between">
          <h1 className="text-[#333] text-[32px] font-[600] inter">
            {invoice?.company?.company_name}
          </h1>
          <div className="flex items-center gap-[10px]">
            <span
              className="py-2 px-4 border border-[#4285F4] rounded-[8px] flex items-center justify-center text-[#4285F4] font-[600] cursor-pointer"
              onClick={(e) =>
                setModalOpen((prev) => ({ ...prev, newVersion: true }))
              }
            >
              Create a new version
            </span>
          </div>
        </section>
      </div>
      <div className="printInvoice border border-gray-200 rounded-md py-5 px-5">
        <div className="space-y-6">
          <div>
            <h1 className="text-[24px] text-primary font-[600] text-center">
              Found multiple versions of this invoice{" "}
            </h1>
            <p className="text-center text-[12px] text-[#666] fount-[500]">
              (click to see details)
            </p>
          </div>
          <div className="grid grid-cols-3 gap-[20px]">
            <div
              className="py-3 p-6 tableBorder cursor-pointer"
              onClick={(e) => navigate(`/invoice/${id}`)}
            >
              <div className="flex items-center gap-[20px]">
                <div className="border-2 border-primary rounded-[50%] h-[60px] w-[60px] flex items-center justify-center">
                  <LiaFileInvoiceDollarSolid
                    size={35}
                    className="text-primary"
                  />
                </div>
                <div>
                  <p className="text-[18px] text-primary font-[600]">
                    Version 1
                  </p>
                  <p>
                    Created on{" "}
                    {new Date(invoice?.createdAt)?.toLocaleDateString("en-ca", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </p>
                </div>
              </div>
            </div>
            {invoice?.versions?.map((d, i) => (
              <div
                key={i}
                className="py-3 p-6 tableBorder cursor-pointer"
                onClick={(e) => navigate(`/invoice/${d?.id}`)}
              >
                <div className="flex items-center gap-[20px]">
                  <div className="border-2 border-primary rounded-[50%] h-[60px] w-[60px] flex items-center justify-center">
                    <LiaFileInvoiceDollarSolid
                      size={35}
                      className="text-primary"
                    />
                  </div>
                  <div>
                    <p className="text-[18px] text-primary font-[600]">
                      Version {i + 2}
                    </p>
                    <p>
                      Created on{" "}
                      {new Date(d?.createdAt)?.toLocaleDateString("en-ca", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* new Version */}
      <Modal
        open={modalOpen?.newVersion}
        onClose={(e) =>
          setModalOpen((prev) => ({ ...prev, newVersion: false }))
        }
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col gap-[10px] w-full ">
            <h1 className="text-[22px]">
              Are you sure you want create a new version of this invoice?
            </h1>
            <h3 className="text-[17px] font-[700] text-primary">
              {invoice?.invoice_number}
            </h3>
            {internalLoader ? (
              <div className="flex justify-between w-full">
                <div></div>
                <CircularProgress
                  className="self-end w-[100%] text-[12px]"
                  size={25}
                />
              </div>
            ) : (
              <section className="w-full flex justify-end">
                <button
                  className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#4285F4] text-white border-none font-[600]"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    setInternalLoader(true);
                    handleNewVersionSubmit();
                  }}
                >
                  Yes
                </button>
                <button
                  className="self-center mr-5 mt-5 w-[25%] py-2 bg-[#a0a4a0] text-white border-none font-[600]"
                  onClick={(e) => {
                    setModalOpen((prev) => ({ ...prev, newVersion: false }));
                  }}
                >
                  No
                </button>
              </section>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default MultiVersionOverview;
