import { Suspense, lazy } from "react";
import "./App.css";
import { isLoggedIn, signIn } from "@mirats/mirats-auth";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import MultiVersionOverview from "./pages/Invoice/MultiVersionOverview";

const AllContextProvider = lazy(() => import("./pages/Context/GlobalContext"));
const AllInvoiceContextProvider = lazy(() =>
  import("./pages/Context/InvoiceContext")
);
const AllClientContextProvider = lazy(() =>
  import("./pages/Context/ClientContext")
);
const AllSuppliersContextProvider = lazy(() =>
  import("./pages/Context/SuppliersContext")
);

// const MainWrapperContainer = lazy(() =>
//   import("./components/MainWrapperContainer")
// );
const Invoices = lazy(() => import("./pages/Invoice/Invoices"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Suppliers = lazy(() => import("./pages/Supplier/Suppliers"));
const CreateInvoices = lazy(() => import("./pages/Invoice/CreateInvoice"));
const EditSuppliers = lazy(() => import("./pages/Supplier/EditSupplier"));
const Client = lazy(() => import("./pages/client/Client"));
const Companies = lazy(() => import("./pages/company/Companies"));
const ProjectOrders = lazy(() => import("./pages/ProjectOrder/ProjectOrders"));
const ClientDetail = lazy(() => import("./pages/client/ClientDetails"));
const CreateCompany = lazy(() => import("./pages/company/CreateCompany"));
const EditCompany = lazy(() => import("./pages/company/CreateCompany"));
const CompanyDetails = lazy(() => import("./pages/company/CompanyDetail"));
const RemittanceAdvice = lazy(() =>
  import("./pages/RemittanceAdvice/RemittanceAdvice")
);
const RemittanceDetails = lazy(() =>
  import("./pages/RemittanceAdvice/RemittanceDetails")
);
const CreateRemittanceAdvice = lazy(() =>
  import("./pages/RemittanceAdvice/CreateRemittanceAdvice")
);
const EditRemittanceAdvice = lazy(() =>
  import("./pages/RemittanceAdvice/CreateRemittanceAdvice")
);
const Payment = lazy(() => import("./pages/Payment"));
const PaymentReceived = lazy(() =>
  import("./pages/paymentReceived/PaymentReceived")
);
const CreatePaymentReceived = lazy(() =>
  import("./pages/paymentReceived/CreatePaymentReceived")
);
const CreditNote = lazy(() => import("./pages/creditNote/CreditNote"));
const CreateCreditNote = lazy(() =>
  import("./pages/creditNote/CreateCreditNote")
);
const DebitNote = lazy(() => import("./pages/debitNote/DebitNote"));
const CreateDebitNote = lazy(() => import("./pages/debitNote/CreateDebitNote"));
const Expenses = lazy(() => import("./pages/expense/Expenses"));
const CreateExpenses = lazy(() => import("./pages/expense/CreateExpense"));
const VendorInvoices = lazy(() => import("./pages/VendorInvoices"));
const ClientReports = lazy(() => import("./pages/ClientReports"));
const PoByVendors = lazy(() => import("./pages/PoByVendors"));
const CreateClient = lazy(() => import("./pages/client/CreateClient"));
const ReceivedPaymentDetails = lazy(() =>
  import("./pages/paymentReceived/ReceivedPaymentDetails")
);
const AllProjectOrdersContextProvider = lazy(() =>
  import("./pages/Context/ProjectOrdersContext")
);
const ProjectOrderDetails = lazy(() =>
  import("./pages/ProjectOrder/ProjectOrderDetails")
);
const InvoiceDetail = lazy(() => import("./pages/Invoice/InvoiceDetail"));
const FullInvoice = lazy(() => import("./pages/Invoice/FullInvoice"));
const Loader = lazy(() => import("./components/Loader"));
const Others = lazy(() => import("./pages/Others/Others"));
const CreditNoteDetail = lazy(() =>
  import("./pages/creditNote/CreditNoteDetail")
);
const FullCreditNote = lazy(() => import("./pages/creditNote/FullCreditNote"));
const DebitNoteDetail = lazy(() => import("./pages/debitNote/DebitNoteDetail"));
const FullDebitNote = lazy(() => import("./pages/debitNote/FullDebitNote"));
const ClientDashBoard = lazy(() => import("./pages/client/ClientDashBoard"));
const ExpenseDetails = lazy(() => import("./pages/expense/ExpenseDetails"));
const Payroll = lazy(() => import("./pages/Payroll/Payroll"));
const ApprovedPayrolls = lazy(() => import("./pages/Payroll/ApprovedPayrolls"));
const ManageUser = lazy(() => import("./pages/manageUser/ManageUser"));
const UserDetails = lazy(() => import("./pages/manageUser/UserDetails"));
const Reconciliation = lazy(() =>
  import("./pages/reconciliation/Reconciliation")
);
const AddReconciliation = lazy(() =>
  import("./pages/reconciliation/AddReconciliation")
);
const ReconciliationDetails = lazy(() =>
  import("./pages/reconciliation/ReconciliationDetails")
);
const SuppliersV2 = lazy(() =>
  import("./pages/Supplier/suppliers-v2/SuppliersV2")
);
const CreateSupplierV2 = lazy(() =>
  import("./pages/Supplier/suppliers-v2/CreateSupplierV2")
);
const ProjectOrdersV2 = lazy(() =>
  import("./pages/ProjectOrder/project-orders-v2/ProjectOrdersV2")
);
const CreateProjectOrdersV2 = lazy(() =>
  import("./pages/ProjectOrder/project-orders-v2/CreateProjectOrdersV2")
);
const ProjectOrdersDetailsV2 = lazy(() =>
  import("./pages/ProjectOrder/project-orders-v2/ProjectOrdersDetailsV2")
);
const PaymentV2 = lazy(() => import("./pages/payment-v2/PaymentV2"));

const UploadInvoice = lazy(() =>
  import("./pages/ProjectOrder/project-orders-v2/UploadInvoice")
);
const PoByVendorsV2 = lazy(() => import("./pages/PoByVendorsV2"));
const VendorInvoicesV2 = lazy(() => import("./pages/VendorInvoicesV2"));
const RemittanceAdviceV2 = lazy(() =>
  import("./pages/RemittanceAdvice/RemittanceAdviceV2.js/RemittanceAdviceV2")
);
const CreateRemittanceAdviceV2 = lazy(() =>
  import(
    "./pages/RemittanceAdvice/RemittanceAdviceV2.js/CreateRemittanceAdviceV2"
  )
);
const RemittanceDetailsV2 = lazy(() =>
  import("./pages/RemittanceAdvice/RemittanceAdviceV2.js/RemittanceDetailsV2")
);

function App() {
  const currentURL = window.location.href;
  const substringsToCheck = [
    "/client/invoice",
    "/client/credit-note",
    "/client/debit-note",
    "/client/dashboard",
    "/approved-payroll",
    "/invoice/supplier",
  ];
  // const { userData, companyData, navData } = useAllContext();

  function ProtectedRoute({ children }) {
    const { user, loading } = isLoggedIn();

    if (user & !loading) {
      // const companyAccess = userData?.pageAccess?.access?.find(
      //   (cm) => cm?.company === companyData?.companyId
      // );
      return children;
      // if (
      //   companyAccess?.pages?.includes?.(
      //     navData?.find((nv) => {
      //       return (
      //         nv?.link?.split("/")?.[1] ===
      //         window.location.pathname.split("/")?.[1]
      //       );
      //     })?.name
      //   )
      // ) {
      // return children;
      // } else {
      //   return <h1>You Dont Have access</h1>;
      // }
    } else {
      signIn();
      return null;
    }
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/client/invoice/:id"
          element={
            <Suspense
              fallback={
                <div className="w-full h-screen  ">
                  <Loader />
                </div>
              }
            >
              <FullInvoice />
            </Suspense>
          }
          exact
        />
        <Route
          path="/client/credit-note/:id"
          element={
            <Suspense
              fallback={
                <div className="w-full h-screen  ">
                  <Loader />
                </div>
              }
            >
              {/* <AllInvoiceContextProvider> */}
              <FullCreditNote />
              {/* </AllInvoiceContextProvider> */}
            </Suspense>
          }
        />
        <Route
          path="/client/debit-note/:id"
          element={
            <Suspense
              fallback={
                <div className="w-full h-screen  ">
                  <Loader />
                </div>
              }
            >
              {/* <AllInvoiceContextProvider> */}
              <FullDebitNote />
              {/* </AllInvoiceContextProvider> */}
            </Suspense>
          }
        />
        <Route
          path="/client/Dashboard/:id"
          element={
            <Suspense
              fallback={
                <div className="w-full h-screen  ">
                  <Loader />
                </div>
              }
            >
              {/* <AllInvoiceContextProvider> */}
              <ClientDashBoard />
              {/* </AllInvoiceContextProvider> */}
            </Suspense>
          }
        />
        <Route
          path="/approved-payroll/:month/:year/:company"
          element={
            <Suspense
              fallback={
                <div className="w-full h-screen  ">
                  <Loader />
                </div>
              }
            >
              <ApprovedPayrolls />{" "}
            </Suspense>
          }
        />
        {/* <Route
          path="/invoice/suppliers/:CID/:PID/:SID/:hash"
          element={<UploadInvoice />}
        /> */}
        <Route
          path="/invoice/suppliers/:sData/:supp"
          element={
            <Suspense
              fallback={
                <div className="w-full h-screen  ">
                  <Loader />
                </div>
              }
            >
              <UploadInvoice />
            </Suspense>
          }
        />
        <Route path="/loader" element={<Loader />} />
      </Routes>
      {!substringsToCheck.some((substring) =>
        currentURL.includes(substring)
      ) ? (
        <AllContextProvider>
          {/* <MainWrapperContainer> */}
          {/* <Suspense
            fallback={
              <div className="w-full h-screen  ">
                <Loader />
              </div>
            }
          > */}
          <ProtectedRoute>
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <AllClientContextProvider>
                        <AllSuppliersContextProvider>
                          <AllProjectOrdersContextProvider>
                            <Dashboard />
                          </AllProjectOrdersContextProvider>
                        </AllSuppliersContextProvider>
                      </AllClientContextProvider>
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/invoice"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <Invoices />
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/reconciliation"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <Reconciliation />
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/reconciliation/add"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <AddReconciliation />
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/reconciliation/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <ReconciliationDetails />
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/invoice/:id/overview"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <AllClientContextProvider>
                        <MultiVersionOverview />
                      </AllClientContextProvider>
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/invoice/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <AllClientContextProvider>
                        <InvoiceDetail />
                      </AllClientContextProvider>
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/invoice/create/:type"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <CreateInvoices />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/invoice/edit/:id/:type/:cid/:inum"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <CreateInvoices />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/credit-note/add/:type"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <CreateCreditNote />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/credit-note/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <CreditNoteDetail />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/credit-note/edit/:id/:type"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <CreateCreditNote />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/debit-note/add/:type"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <CreateDebitNote />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/debit-note/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <DebitNoteDetail />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/debit-note/edit/:id/:type"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <CreateDebitNote />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/suppliers"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <Suppliers />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/suppliers-v2"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <SuppliersV2 />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/suppliers-v2/add"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <CreateSupplierV2 />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/suppliers/edit/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <EditSuppliers />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/suppliers-v2/edit/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <CreateSupplierV2 />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/clients"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <Client />
                    </AllClientContextProvider>{" "}
                  </Suspense>
                }
              />

              <Route
                path="/clients/add"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <CreateClient />
                  </Suspense>
                }
              />

              <Route
                path="/clients/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <ClientDetail />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />

              <Route
                path="/client-report"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <ClientReports />
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/companies"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <Companies />
                  </Suspense>
                }
              />
              <Route
                path="/payments"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <Payment />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/payments-v2"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <PaymentV2 />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/payment-received"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <PaymentReceived />
                  </Suspense>
                }
              />
              <Route
                path="/credit-note"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <CreditNote />
                  </Suspense>
                }
              />
              <Route
                path="/debit-note"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <DebitNote />
                  </Suspense>
                }
              />
              <Route
                path="/expenses"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <Expenses />
                  </Suspense>
                }
              />
              <Route
                path="/expenses/edit/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <CreateExpenses />
                  </Suspense>
                }
              />
              <Route
                path="/payroll"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <Payroll />
                  </Suspense>
                }
              />
              <Route
                path="/others"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <Others />
                  </Suspense>
                }
              />
              <Route
                path="/manage-users"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <ManageUser />
                  </Suspense>
                }
              />
              <Route
                path="/manage-users/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <UserDetails />
                  </Suspense>
                }
              />
              <Route
                path="/vendor-invoices"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <VendorInvoices />
                  </Suspense>
                }
              />
              <Route
                path="/vendor-invoices-v2"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <VendorInvoicesV2 />
                  </Suspense>
                }
              />
              <Route
                path="/clients/edit/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <CreateClient />
                  </Suspense>
                }
              />
              <Route
                path="/companies/add"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <CreateCompany />
                  </Suspense>
                }
              />
              <Route
                path="/companies/edit/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <EditCompany />
                  </Suspense>
                }
              />
              <Route
                path="/companies/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <CompanyDetails />
                  </Suspense>
                }
              />

              <Route
                path="/payment-received/add/:type"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <CreatePaymentReceived />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/payment-received/edit/:id/:type"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllClientContextProvider>
                      <AllInvoiceContextProvider>
                        <CreatePaymentReceived />
                      </AllInvoiceContextProvider>
                    </AllClientContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/payment-received/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <ReceivedPaymentDetails />
                  </Suspense>
                }
              />

              <Route
                path="/expenses/add"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <CreateExpenses />
                  </Suspense>
                }
              />
              <Route
                path="/expenses/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <ExpenseDetails />
                  </Suspense>
                }
              />
              <Route
                path="/remittance-advice/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <RemittanceDetails />
                  </Suspense>
                }
              />
              <Route
                path="/remittance-advice-v2/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <RemittanceDetailsV2 />
                  </Suspense>
                }
              />
              <Route
                path="/remittance-advice/add"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <CreateRemittanceAdvice />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/remittance-advice-v2/add"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <CreateRemittanceAdviceV2 />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/remittance-advice/edit/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <EditRemittanceAdvice />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/remittance-advice-v2/edit/:id"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <CreateRemittanceAdviceV2 />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/remittance-advice"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <RemittanceAdvice />
                  </Suspense>
                }
              />
              <Route
                path="/remittance-advice-v2"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <RemittanceAdviceV2 />
                  </Suspense>
                }
              />

              <Route
                path="/project-orders"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllProjectOrdersContextProvider>
                      <ProjectOrders />
                    </AllProjectOrdersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/project-orders-v2"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllProjectOrdersContextProvider>
                      <ProjectOrdersV2 />
                    </AllProjectOrdersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/project-orders-v2/add"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <AllClientContextProvider>
                        <AllProjectOrdersContextProvider>
                          <CreateProjectOrdersV2 />
                        </AllProjectOrdersContextProvider>
                      </AllClientContextProvider>
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/project-orders/:id/:status"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <AllClientContextProvider>
                        <AllProjectOrdersContextProvider>
                          <ProjectOrderDetails />
                        </AllProjectOrdersContextProvider>
                      </AllClientContextProvider>
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/project-orders-v2/:id/:status"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllInvoiceContextProvider>
                      <AllClientContextProvider>
                        <AllSuppliersContextProvider>
                          <AllProjectOrdersContextProvider>
                            <ProjectOrdersDetailsV2 />
                          </AllProjectOrdersContextProvider>
                        </AllSuppliersContextProvider>
                      </AllClientContextProvider>
                    </AllInvoiceContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/po-by-vendor"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <PoByVendors />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
              <Route
                path="/po-by-vendor-v2"
                element={
                  <Suspense
                    fallback={
                      <div className="w-full h-screen  ">
                        <Loader />
                      </div>
                    }
                  >
                    <AllSuppliersContextProvider>
                      <PoByVendorsV2 />
                    </AllSuppliersContextProvider>
                  </Suspense>
                }
              />
            </Routes>
          </ProtectedRoute>
          {/* </Suspense> */}
          {/* </MainWrapperContainer> */}
        </AllContextProvider>
      ) : (
        ""
      )}
    </BrowserRouter>
  );
}

export default App;
