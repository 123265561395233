/* eslint-disable react-hooks/exhaustive-deps */
/*  eslint-disable no-unused-vars */
import axios from "axios";
import { memo, useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { useAllContext } from "./GlobalContext";
import { BASE_URL, tmpToken } from "../../utils/utils";

const AllInvoiceContext = createContext();

export const useAllInvoiceContext = () => {
  return useContext(AllInvoiceContext);
};

const AllInvoiceContextProvider = ({ children }) => {
  const { companyData, globalLoader, setGlobalLoader } = useAllContext();

  //GET ALL INVOICES:
  const [allInvoices, setAllInvoices] = useState([]);
  async function getAllInvoices() {
    setGlobalLoader((prev) => ({ ...prev, invoiceData: true }));
    try {
      let invoicesData = await axios.get(
        `${BASE_URL}/invoice${
          companyData?.companyId ? `?companyId=${companyData?.companyId}` : ""
        }`,
        {
          headers: {
            // "x-access-token": getCookie("token"),
            "x-access-token": tmpToken,
          },
        }
      );

      setAllInvoices(invoicesData.data);
      setGlobalLoader((prev) => ({ ...prev, invoiceData: false }));
    } catch (error) {
      console.error(error);
    }
  }

  // GET ALL INVOICES V2:
  const [allInvoicesV2, setAllInvoicesV2] = useState([]);
  const getAllInvoicesV2 = async (company, month, year) => {
    setGlobalLoader((prev) => ({ ...prev, invoiceData: true }));
    try {
      let invoiceData = await axios.get(
        `${BASE_URL}/invoiceV2?company=${company}&month=${month}&year=${year}`,
        {
          headers: {
            "x-access-token": tmpToken,
          },
        }
      );
      setAllInvoicesV2(invoiceData.data.invoices);
      setGlobalLoader((prev) => ({ ...prev, invoiceData: false }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("invoice context got called");
    getAllInvoices();
  }, [companyData?.companyId]);

  const value = {
    getAllInvoices,
    allInvoices,
    setAllInvoices,

    allInvoicesV2,
    setAllInvoicesV2,
    getAllInvoicesV2,
  };

  return (
    <AllInvoiceContext.Provider value={value}>
      {children}
    </AllInvoiceContext.Provider>
  );
};

export default memo(AllInvoiceContextProvider);
