import { getAuth } from "@mirats/mirats-auth";
import CryptoJS from "crypto-js";

//Development:
// export const BASE_URL = "http://localhost:8689/v1/finance";

// proudction
export const BASE_URL = "https://financeapis.miratsgroup.com/v1/finance";

export function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

const user = await getAuth();
export const tmpToken = user?.data;

//ADD DAYS:
export function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + (days - 1));
  return result?.toLocaleDateString("en-ca");
}

//GET NUMBER OF DAYS IN BETWEEN TWO DATES:
export const getNumberOfDays = (firstDate, secondDate) => {
  let count = 0;
  let start = new Date(firstDate);
  let end = new Date(secondDate);
  let loop = new Date(start);
  console.log(start, end);
  while (loop <= end) {
    count++;
  }
  let newDate = loop?.setDate(loop.getDate() + 1);
  loop = new Date(newDate);
  return count;
};

//FIREBASE DATE TO JS DATE
export function convertToJsDate(data) {
  if (
    !data ||
    typeof data.seconds !== "number" ||
    typeof data.nanoseconds !== "number"
  ) {
    throw new Error("Invalid data");
  }
  const millisecondsFromSeconds = data.seconds * 1000;
  const millisecondsFromNanoseconds = data.nanoseconds / 1000000;
  const totalMilliseconds =
    millisecondsFromSeconds + millisecondsFromNanoseconds;
  return new Date(totalMilliseconds);
}
export const decryptText = (encryptText) => {
  var reb64 = CryptoJS.enc.Hex.parse(encryptText);
  var bytes = reb64.toString(CryptoJS.enc.Base64);
  var decrypt = CryptoJS.AES.decrypt(
    bytes,
    "2decc91edd774afd52509719714153cced08b67bfdf6cfb39e694c23c5b5b7aeddb90f48d534813ba7ce68c6d8c89e8b8750dcdd5cddd4e27300bc510391f6c9"
  );
  var plain = decrypt.toString(CryptoJS.enc.Utf8);
  return plain;
};
export const CID = "65ded9cd7897d9cda0ada2f3";
export const responsiveGrid = (min) => {
  return `repeat(auto-fit, minmax(${min}px, 1fr))`;
};
