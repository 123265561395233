/* eslint-disable react-hooks/exhaustive-deps */
/*  eslint-disable no-unused-vars */
import axios from "axios";
import { memo, useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { BASE_URL, tmpToken } from "../../utils/utils";
import { useAllContext } from "./GlobalContext";

const AllClientContext = createContext();

export const useAllClientContext = () => {
  return useContext(AllClientContext);
};

const AllClientContextProvider = ({ children }) => {
  const { setGlobalLoader, companyData } = useAllContext();

  //GET ALL CLIENTS:
  const [allClients, setAllClients] = useState([]);
  async function getAllClients() {
    console.log("client context called");
    setGlobalLoader((prev) => ({ ...prev, clientsData: true }));
    try {
      const clientsData = await axios.get(
        `${BASE_URL}/client?company=${companyData?.companyId}`,
        {
          headers: {
            // "x-access-token": getCookie("token"),
            "x-access-token": tmpToken,
          },
        }
      );
      setGlobalLoader((prev) => ({ ...prev, clientsData: false }));
      setAllClients(clientsData?.data);
    } catch (error) {
      console.log(error.message);
    }
  }

  // useEffect(() => {
  //   getAllClients();
  // }, [companyData?.companyId]);

  // console.log(allClients);

  const value = {
    getAllClients,
    allClients,
    setAllClients,
  };

  return (
    <AllClientContext.Provider value={value}>
      {children}
    </AllClientContext.Provider>
  );
};

export default memo(AllClientContextProvider);
