/* eslint-disable react-hooks/exhaustive-deps */
/*  eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import AllContextProvider from "./pages/Context/GlobalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <AllContextProvider>
  <App />
  // </AllContextProvider>
);

reportWebVitals();
